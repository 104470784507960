// import httpClient from '@utils/httpClient';

const getters = {
  loading: (state) => state.loading,
  loaded: (state) => state.loaded,
};

const actions = {
};

const mutations = {
};

const state = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
