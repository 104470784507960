import BaseModule from '@/utils/BaseFireVuex';

/* eslint-disable max-classes-per-file */

class Account {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }

  toString() {
    return this.name;
  }
}

const accountConverter = {
  toFirestore(account) {
    return {
      name: account.name,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Account(data);
  },
};

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
};

const options = {
  name: 'account',
  path: 'account',
  schema,
  converter: accountConverter,
};

class AccountModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      currentPath: (state) => `account/${state.current.id}`,
    };
  }
}

export default new AccountModule(options).getModule();
/* eslint-enable max-classes-per-file */
