// import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';
import { auth } from '@/firebase';

/* eslint-disable max-classes-per-file */

class People {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.event = data.event;
    this.password = data.password;
    this.type = data.type;
    this.organization = data.organization;
    this.phone = data.phone;
    this.location = data.location;
  }

  toString() {
    return this.name;
  }
}

const PeopleConverter = {
  toFirestore(people) {
    console.log(people);
    const type = typeof people.type !== 'undefined' ? people.type : null;
    const location = typeof people.location !== 'undefined' ? people.location : null;
    const organization = typeof people.organization !== 'undefined' ? people.organization : null;
    const phone = typeof people.phone !== 'undefined' ? people.phone : null;
    /*
    if (typeof people.type !== 'undefined') {
      type = people.type;
    }
    */
    return {
      name: people.name,
      email: people.email,
      password: people.password,
      event: people.event,
      location,
      organization,
      phone,
      type,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new People(data);
  },
};

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  email: {
    type: 'email',
    required: true,
  },
  password: {
    type: 'string',
    required: true,
  },
  event: {
    type: 'string',
    required: true,
  },
  phone: {
    type: 'string',
    required: false,
  },
  organization: {
    type: 'string',
    required: false,
  },
  location: {
    type: 'string',
    required: false,
  },
  type: {
    type: 'string',
    required: true,
  },
};

const options = {
  name: 'people',
  path: 'invitations',
  schema,
  converter: PeopleConverter,
  parentModule: 'event',
  parentIdField: 'eventId',
};

class PeopleModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      currentPath: (state, getters, rootState) => `account/${rootState.account.current.id}/event/${rootState.event.current.id}/invitations/${rootState.people.current.id}`,
    };
  }

  actions() {
    return {
      ...super.actions(),
      logout() {
        localStorage.removeItem('t');
        return auth().signOut();
      },

    };
  }
}

export default new PeopleModule(options).getModule();
/* eslint-enable max-classes-per-file */
